/** @jsx jsx */
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FC, useState, Fragment, useEffect } from 'react'
import { Box, jsx, Container, Text, Button, Flex } from 'theme-ui'

import { DialogStateReturn, useDialogState } from 'reakit/Dialog'

import Halo from '../components/halo'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Modal from '../components/modal'
import Modal2 from '../components/modal2'
import Navigation from '../components/navig'

import mountAndRunCharAnimation from '../components/scenes/cube.ts'
// import mountAndRunCharAnimation from "../animation/character";

import { Clickable } from 'reakit/Clickable'

export interface ProfileProps {
  // data: {
  //   file: {
  //     childImageSharp: { fluid: any }
  //   }
  // }
}
const PrivacyPage: FC<ProfileProps> = (
  {
    // data: {
    //   file: {
    //     childImageSharp: { fluid },
    //   },
    // },
  },
) => {
  const [showCard, setShowCard] = useState<boolean>(false)
  const [card, setCard] = useState<any>()
  const dialog = useDialogState()

  const showCardNow = () => {
    setShowCard(true)
  }

  const hideCardNow = () => {
    console.log('hideCardNow')
    // setShowCard(false)
  }

  const onClose = () => {
    dialog.hide()
    console.log('onClose')
    // setShowCard(false)
    // setCard(false)
  }

  const modalNow = (c: any) => {
    dialog.show()
    setCard(c)
    setShowCard(true)
  }

  const Links = [1, 2, 3, 4, 5]

  useEffect(() => {
    mountAndRunCharAnimation('.char')
  }, [])

  return (
    <Box sx={{ fontFamily: 'body', position: 'relative' }}>
      {/* <Navigation alt={false} /> */}

      <Box variant="box.plateBig" sx={{ px: 4, bg: 'red.4', minHeight: '100vh' }}>
        <Halo title="Privacy Policy | Functonary" url="https://nft.rage.fan/privacy" />
        <Container sx={{ color: '#000' }}>
          <Text sx={{ fontSize: 6, mb: 6 }}>Modal</Text>
          <Box as="canvas" className="char" sx={{ bg: 'red.3', border: 'solid 1px #fff', width: '400px', height: '700px' }} />          
          {/* <canvas className="char" sx={{ bg: 'red.3', border: 'solid 1px #fff', width: '300px', height: '150px' }} /> */}
        </Container>
      </Box>
    </Box>
  )
}

export default PrivacyPage
